@import "../../_globalColor";

.project-card-div {
  color: $textColor;
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadowDark 0px 5px 15px -10px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  height: 100%;
  max-height: 380px;
  
  &:hover {
    box-shadow: $lightBoxShadowDark 0px 15px 25px -10px;
    transform: translateY(-5px);
  }
}

.project-card-header {
  position: relative;
}

.project-image-div {
  position: relative;
  height: 160px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  
  &:hover {
    transform: scale(1.08);
  }
}

.project-name {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 18px 10px;
  color: $textColor;
  /* For truncating text after 1 line */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-description {
  font-size: 14px;
  color: $textColor;
  margin: 0 18px 15px;
  line-height: 1.5;
  flex: 1;
  
  /* For truncating text after 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.project-tech-stack {
  display: flex;
  flex-wrap: wrap;
  margin: 0 18px 15px;
}

.tech-tag {
  background-color: $buttonColor;
  color: $textColorDark;
  font-size: 11px;
  padding: 4px 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  
  &:hover {
    background-color: darken($buttonColor, 10%);
    transform: translateY(-1px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
  }
}

.more-tag {
  background-color: transparentize($buttonColor, 0.7);
  color: $textColor;
}

.project-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 18px 18px;
  margin-top: auto;
}

.project-button {
  padding: 8px 15px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 13.5px;
  cursor: pointer;
  transition: all 0.25s ease;
  flex: 1;
  margin: 0 5px;
  border: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
    transform: translateX(-100%);
    transition: transform 0.2s ease-out;
  }
  
  &:hover::after {
    transform: translateX(0);
  }
}

.demo-button {
  background-color: $buttonColor;
  color: $textColorDark;
  
  &:hover {
    background-color: darken($buttonColor, 8%);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.repo-button {
  background-color: transparent;
  color: $textColor;
  border: 1.5px solid $buttonColor;
  
  &:hover {
    background-color: rgba($buttonColor, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
  
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
  }
}

/* For dark mode */
.dark-card-mode {
  background-color: $darkBackground;
  box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 0.4);
  
  &:hover {
    box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.5);
  }
  
  .project-name, .project-description {
    color: $textColorDark;
  }
  
  .more-tag {
    color: $textColorDark;
    background-color: transparentize(lighten($buttonColor, 10%), 0.7);
  }
  
  .repo-button {
    color: $textColorDark;
    border: 1.5px solid lighten($buttonColor, 10%);
    
    &:hover {
      background-color: rgba(lighten($buttonColor, 10%), 0.15);
    }
  }
  
  .tech-tag {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }
  }
  
  .project-button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .project-image-div {
    height: 150px;
  }
  
  .project-name {
    margin: 14px 16px 8px;
    font-size: 17px;
  }
  
  .project-description {
    margin: 0 16px 12px;
    font-size: 13.5px;
  }
  
  .project-tech-stack {
    margin: 0 16px 12px;
  }
  
  .project-buttons {
    padding: 0 16px 16px;
  }
}

@media (max-width: 576px) {
  .project-image-div {
    height: 180px;
  }
  
  .project-card-div {
    max-height: 400px;
  }
  
  .project-name {
    margin: 15px 16px 8px;
    font-size: 18px;
  }
  
  .project-description {
    -webkit-line-clamp: 3;
  }
}