/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiple types of elements. 
For greater control over colors visit each components .scss file. */

// button colors
$buttonColor: #00796b; // Teal
$buttonHover: #48a999; // Lighter teal
$topButtonHover: #212121; // Darker shade for better contrast

// text colors light theme
$titleColor: #004d40; // Dark teal
$textColor: #004d40; // Consistent dark teal
$subTitle: #26a69a; // Medium teal
$cardSubtitle: #757575; // Neutral gray
$talkCardSubTitle: #616161; // Slightly darker gray
$blogCardTitleColor: #00796b; // Teal

// text color dark theme
$textColorDark: #e0f7fa; // Light cyan

// toggle switch colors
$toggleCheck: #26c6da; // Bright cyan
$toggleSwitchSliderBG: #b0bec5; // Light gray-blue

// githubRepo specific colors
$githubRepoCardLanguageColorBG: #00acc1; // Bright cyan
$githubRepoCardColor: rgb(44, 62, 80); // Darker shade for better contrast
$githubRepoCardRepoCardStatsColor: rgb(76, 86, 106); // Muted gray-blue
$githubRepoCardRepoNameColor: rgb(27, 35, 46); // Darker shade
$githubProfileCardLocationTS: #fff8e1; // Light yellow
$githubProfileCardBorder: #00bfa5; // Teal

// light background colors
$lightBackground1: #ffffff; // Pure white
$lightBackground2: rgb(245, 245, 245); // Light gray
$lightBackground3: #e0f2f1; // Light teal
$blogCardContainerColor: #80cbc4; // Medium teal
// dark background colors
$darkBackground: #1c2833; // Dark blue-gray

// light theme box shadows
$lightBoxShadowDark: rgba(0, 0, 0, 0.3); // Slightly darker shadow
$lightBoxShadow: rgba(0, 0, 0, 0.15); // Slightly darker shadow
$lightBoxShadowDarker: rgba(0, 0, 0, 0.4); // Slightly darker shadow

// dark theme box shadows
$darkBoxShadow: rgba(255, 255, 255, 0.2); // Light white shadow
$darkBoxShadow2: #ffffff; // Pure white

// linear gradients
$experienceCardBlurredDivLG: linear-gradient(
  rgba(0, 0, 0, 0.5),
  rgba(0, 0, 0, 0.3)
); // Slightly darker gradient
$experienceCardBannerLG: linear-gradient(
  rgba(0, 0, 0, 0.8),
  rgba(0, 0, 0, 0.6)
); // Slightly darker gradient

// borders
$progressBarBackground: rgb(220, 220, 220); // Lighter gray
$lightBorder1: rgba(189, 189, 189, 0.5); // Slightly darker gray
$educationCardBorder: #00acc1; // Teal

// hovers
$achievementCertificateCardHoverDark: rgba(255, 255, 255, 0.3); // Slightly brighter hover
$blogCardHoverSmallColor: rgba(255, 255, 255, 0.9); // Slightly brighter hover
$headerHoverBG: #eeeeee; // Light gray
$contactDetailHoverTS: #cccccc; // Light gray

// misc backgrounds
$progressBarSpanBG: #4db6ac; // Teal
$iconBackground: #424242; // Dark gray
$appHeaderBG: #004d40; // Dark teal

// misc colors
$skillsColor: #645beb;
$appLink: #00e676; // Bright green

// social media icons
$faceBook: #4267b2; // Facebook blue
$linkedin: #0077b5; // LinkedIn blue
$github: #24292e; // GitHub dark gray
$gitlab: #fc6d26; // GitLab orange
$google: #db4437; // Google red
$twitter: #1da1f2; // Twitter blue
$medium: #000000; // Black
$stackoverflow: #f48024; // Stack Overflow orange
$instagram: #e4405f; // Instagram pink
$kaggle: #20beff; // Kaggle blue
