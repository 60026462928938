@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.software-skill-inline > i {
  color: black; // Start with black color
  position: relative;
  z-index: 1;
  transition: color 1.5s ease;
}

.software-skill-inline:hover > i {
  color: var(--icon-color); // Change to the color specified in the inline style
}

.software-skill-inline::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-image: url('https://web.archive.org/web/20160312084140im_/http://splatoon.nintendo.com/assets/img/nav-bg-fill-blue.png?1443460871');
  background-repeat: repeat-x;
  background-position: 0 -100%;
  transition: background-position 1.5s ease;
  -webkit-background-clip: text;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.software-skill-inline:hover::before {
  background-position: 0 0;
}

.software-skill-inline > p {
  color: black; // Start with black color for the text
  font-size: 10px;
  margin-top: 5px;
  transition: color 1.5s ease; // Smooth transition for text color
}

.software-skill-inline:hover > p {
  color: var(--icon-color); // Change to the color specified in the inline style
}