// src/components/twitter/twitter.scss
.centerContent {
  text-align: center;
  margin: auto;
  display: block;
  padding: 1.5rem;
  border-radius: 8px;
  transition: background 0.3s, box-shadow 0.3s;
}

.tw-main-div {
  margin: 15px;
  padding: 1.5rem;
  border-radius: 10px;
  transition: background 0.3s, box-shadow 0.3s;
  background: #ffffff; // Default background for light mode
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.centerContent {
  background: #f5f8fa; // Default background for light mode
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .tw-main-div {
  background: #15202b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.dark-mode .centerContent {
  background: #1b2836;
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.error {
  background: #ffcccc;
  color: #a94442;
  border: 1px solid #ebccd1;
}

@media (max-width: 768px) {
  .centerContent {
    padding: 1rem;
  }
  .tw-main-div {
    padding: 1rem;
  }
}
